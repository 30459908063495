import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MemoryPhaseIndex, Team } from '@api/models/enums';
import { MemoryGrid } from '@api/models/memory-grid.entity';
import { Player } from '@api/models/player.entity';
import { MemoryPhaseSockets } from '@core/services/sockets/memory-phase.sockets';
import { Store } from '@ngrx/store';

import { PlayersFacade } from '../players/players.facade';
import { MemoryPhaseActions } from './memory-phase.actions';
import { MemoryPhaseSelectors } from './memory-phase.selectors';

export type ScoreByTeam = {
	team: Team;
	score: number;
	indexHitted: MemoryPhaseIndex[];
};

@Injectable()
export class MemoryPhaseFacade {
	public scoresByTeam$: Observable<ScoreByTeam[]> = this.store
		.select(MemoryPhaseSelectors.grids)
		.pipe(
			map((grids: MemoryGrid[]) =>
				grids.map(({ team, score, cells }: MemoryGrid) => ({
					team,
					score,
					indexHitted: cells
						.filter((cell) => cell.hitted)
						.map((cell) => cell.wordNumber),
				})),
			),
		);

	public currentPlayerGridId$: Observable<string> =
		this.playersFacade.current$.pipe(
			withLatestFrom(this.store.select(MemoryPhaseSelectors.grids)),
			map(([player, grids]: [Player, MemoryGrid[]]) => {
				const grid = grids.find((grid) => grid.team === player.team);

				return grid ? grid._id : null;
			}),
		);

	public grids$: Observable<MemoryGrid[]> = this.store.select(
		MemoryPhaseSelectors.grids,
	);

	public gridIds$: Observable<string[]> = this.store
		.select(MemoryPhaseSelectors.grids)
		.pipe(
			map((grids: MemoryGrid[]) =>
				grids.map((grid: MemoryGrid) => grid._id),
			),
		);

	public over$: Observable<boolean> = this.store.select(
		MemoryPhaseSelectors.over,
	);

	public get firstGridId(): string {
		return this._firstGridId;
	}

	private _firstGridId: string;

	constructor(
		private store: Store,
		private playersFacade: PlayersFacade,
		private memoryPhaseSockets: MemoryPhaseSockets,
	) {
		this.gridIds$.subscribe(
			([firstGridId]: string[]) => (this._firstGridId = firstGridId),
		);

		this.memoryPhaseSockets.finish$.subscribe(() =>
			this.store.dispatch(MemoryPhaseActions.finishSuccess()),
		);
	}
}
