import { PhaseKind } from '@api/models/enums';
import { MemoryPhase } from '@api/models/memory-phase.entity';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Phase } from '@bussiness/custom-models/game.custom-models';
import { createSelector } from '@ngrx/store';

import { GamesState } from '../games.reducer';
import { selectGames } from '../games.selectors';

export class MemoryPhaseSelectors {
	public static grids = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			if (!gamesState.current) return null;

			const memoryPhase = gamesState.current.phases.find(
				({ kind }: Phase) => kind === PhaseKind.Memory,
			) as MemoryPhase;

			if (!memoryPhase) return null;

			return memoryPhase.grids;
		},
	);

	public static over = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			if (!gamesState.current) return null;

			const memoryPhase = gamesState.current.phases.find(
				({ kind }: Phase) => kind === PhaseKind.Memory,
			) as MemoryPhase;

			if (!memoryPhase) return null;

			return memoryPhase.over;
		},
	);
}
