import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { MemoryCell } from '@api/models/memory-cell.entity';
import { MemoryPhaseCellUpdatedEvent } from '@core/models/games/events/memory-phase.events';
import { MemoryPhaseSockets } from '@core/services/sockets/memory-phase.sockets';
import { Actions, concatLatestFrom, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { MemoryGridSelectors } from '../memory-grid/memory-grid.selectors';
import {
	MemoryCellActions,
	MemoryCellUpdateSuccessAction,
} from './memory-cell.actions';
import { MemoryCellSelectors } from './memory-cell.selectors';

@Injectable()
export class MemoryCellFacade {
	public current$ = (gridId: string): Observable<MemoryCell> =>
		this.store.select(MemoryCellSelectors.current(gridId));

	// Actions
	public updateSuccessAction$: Observable<MemoryCellUpdateSuccessAction> =
		this.actions$.pipe(ofType(MemoryCellActions.updateSuccess));

	constructor(
		private store: Store,
		private actions$: Actions,
		private memoryPhaseSockets: MemoryPhaseSockets,
	) {
		this.memoryPhaseSockets.cellUpdated$
			.pipe(
				concatLatestFrom(
					({ memoryGridId }: MemoryPhaseCellUpdatedEvent) => [
						this.store.select(
							MemoryGridSelectors.cellsHitted(memoryGridId),
						),
					],
				),
			)
			.subscribe(
				([{ memoryGridId, cell }, previousCellsHitted]: [
					MemoryPhaseCellUpdatedEvent,
					MemoryCell[],
				]) =>
					this.store.dispatch(
						MemoryCellActions.updateSuccess({
							gridId: memoryGridId,
							cell,
							previousCellsHitted,
						}),
					),
			);
	}

	public update(gridId: string, answerKind: 'hit' | 'miss'): void {
		this.store.dispatch(MemoryCellActions.update({ gridId, answerKind }));
	}
}
