import { PhaseKind } from '@api/models/enums';
import { MemoryCell } from '@api/models/memory-cell.entity';
import { MemoryGrid } from '@api/models/memory-grid.entity';
import { MemoryPhase } from '@api/models/memory-phase.entity';
import { MemoryWordsGroup } from '@api/models/memory-words-group.entity';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Phase } from '@bussiness/custom-models/game.custom-models';
import { createSelector } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import { selectGames } from '../../games.selectors';

export class MemoryGridSelectors {
	public static cells = (gridId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const grid = getGridFrom(gamesState, gridId);
			if (!grid) return null;

			return grid.cells;
		});

	public static clue = (gridId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const grid = getGridFrom(gamesState, gridId);
			if (!grid) return null;

			return (grid.wordsGroup as MemoryWordsGroup).clue;
		});

	public static playing = (gridId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const grid = getGridFrom(gamesState, gridId);
			if (!grid) return null;

			return grid.playing;
		});

	public static time = (gridId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const grid = getGridFrom(gamesState, gridId);
			if (!grid) return null;

			return grid.time;
		});

	public static team = (gridId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const grid = getGridFrom(gamesState, gridId);
			if (!grid) return null;

			return grid.team;
		});

	public static turnOwner = (gridId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const grid = getGridFrom(gamesState, gridId);
			if (!grid) return null;

			return grid.turnOwner;
		});

	public static status = (gridId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const grid = getGridFrom(gamesState, gridId);
			if (!grid) return null;

			return grid.status;
		});

	public static cellsHitted = (gridId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const grid = getGridFrom(gamesState, gridId);
			if (!grid) return null;

			return grid.cells.filter(({ hitted }: MemoryCell) => hitted);
		});

	public static score = (gridId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const grid = getGridFrom(gamesState, gridId);
			if (!grid) return null;

			return grid.score;
		});

	public static over = (gridId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const grid = getGridFrom(gamesState, gridId);
			if (!grid) return null;

			return grid.over;
		});
}

const getGridFrom = (gamesState: GamesState, gridId: string): MemoryGrid => {
	if (!gamesState.current) return null;

	const memoryPhase = gamesState.current.phases.find(
		({ kind }: Phase) => kind === PhaseKind.Memory,
	) as MemoryPhase;

	if (!memoryPhase) return null;

	return memoryPhase.grids.find(({ _id }: MemoryGrid) => _id === gridId);
};
