export enum FlipperCellStatus {
	None = 'none',
	Hitted = 'hitted',
	Missed = 'missed',
}

/**
 * If you change bellow value, you must update $flipper-cell-animation-duration constant value
 * in projects\components\src\assets\styles\components\game\memory-phase\_flipper-cell.sass
 */
export const FLIP_ANIMATION_DURATION = 1500;
export const BASE_CLASS_NAME = 'plc-flipper-cell--';

export class FlipperCellChange {
	public status: FlipperCellStatus;
	public id: string;
}
