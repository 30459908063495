import { EMPTY, interval, Observable, Subscription } from 'rxjs';
import { delay, filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MemoryGridStatus, MemoryPhaseIndex, Team } from '@api/models/enums';
import { MemoryCell } from '@api/models/memory-cell.entity';
import { MemoryPhaseConstants } from '@api/models/memory-phase.constants';
import { Player } from '@api/models/player.entity';
import { DeviceFacade } from '@bussiness/store/features/device/device.facade';
import { FLIP_ANIMATION_DURATION } from '@components/modules/game/memory-phase/flipper-cell/flipper-cell.models';
import {
	MemoryPhaseChangeTimeEvent,
	MemoryPhaseGridFixedEvent,
	MemoryPhaseGridMissedEvent,
	MemoryPhaseGridUpdatedEvent,
} from '@core/models/games/events/memory-phase.events';
import { MemoryPhaseSockets } from '@core/services/sockets/memory-phase.sockets';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { GamesSelectors } from '../../games.selectors';
import { PlayersFacade } from '../../players/players.facade';
import { MemoryCellActions } from '../memory-cell/memory-cell.actions';
import { MemoryGridActions } from './memory-grid.actions';
import { MemoryGridSelectors } from './memory-grid.selectors';

const ONE_SECOND = 1000;

@Injectable()
export class MemoryGridFacade {
	public cells$ = (gridId: string): Observable<MemoryCell[]> =>
		this.store.select(MemoryGridSelectors.cells(gridId));

	public clue$ = (gridId: string): Observable<string> =>
		this.store
			.select(MemoryGridSelectors.clue(gridId))
			.pipe(
				map(
					(clue: string) =>
						`${clue.charAt(0).toUpperCase()}${clue.slice(1)}`,
				),
			);

	public playing$ = (gridId: string): Observable<boolean> =>
		this.store.select(MemoryGridSelectors.playing(gridId));

	public time$ = (gridId: string): Observable<number> =>
		this.store.select(MemoryGridSelectors.time(gridId));

	public team$ = (gridId: string): Observable<Team> =>
		this.store.select(MemoryGridSelectors.team(gridId));

	public status$ = (gridId: string): Observable<MemoryGridStatus> =>
		this.store.select(MemoryGridSelectors.status(gridId));

	public turnOwner$ = (gridId: string): Observable<Player> =>
		this.store.select(MemoryGridSelectors.turnOwner(gridId)).pipe(
			withLatestFrom(this.playersFacade.all$),
			map(([turnOwnerId, players]: [string, Player[]]) =>
				players.find((player) => player._id === turnOwnerId),
			),
		);

	public cellsHitted$ = (gridId: string): Observable<MemoryCell[]> =>
		this.store.select(MemoryGridSelectors.cellsHitted(gridId));

	public score$ = (gridId: string): Observable<number> =>
		this.store.select(MemoryGridSelectors.score(gridId));

	public over$ = (gridId: string): Observable<boolean> =>
		this.store.select(MemoryGridSelectors.over(gridId));

	// Actions
	public fixSuccessAction$ = this.actions$.pipe(
		ofType(MemoryGridActions.fixSuccess),
	);

	private get _intervalSubscriptionIsOpen(): boolean {
		return this._intervalSubscription && !this._intervalSubscription.closed;
	}

	private _interval$: Observable<number> = interval(ONE_SECOND);
	private _intervalSubscription: Subscription;
	private _timeoutSubscription: Subscription;

	constructor(
		private store: Store,
		private actions$: Actions,
		private playersFacade: PlayersFacade,
		private memoryPhaseSockets: MemoryPhaseSockets,
		private deviceFacade: DeviceFacade,
	) {
		this.memoryPhaseSockets.timeChanged$
			.pipe(
				withLatestFrom(this.playersFacade.currentUserIsPresenter$),
				filter(([, isPresenter]) => !isPresenter),
			)
			.subscribe(
				([{ memoryGridId, time }]: [
					MemoryPhaseChangeTimeEvent,
					boolean,
				]) => {
					this.store.dispatch(
						MemoryGridActions.updateSuccess({
							grid: {
								_id: memoryGridId,
								time,
							},
						}),
					);
				},
			);

		this.memoryPhaseSockets.gridUpdated$
			.pipe(
				tap(({ memoryGrid }: MemoryPhaseGridUpdatedEvent) => {
					const { _id, over, time, score } = memoryGrid;
					if (over) this.toggleTimer(_id, false);

					this.store.dispatch(
						MemoryGridActions.updateSuccess({
							grid: memoryGrid,
						}),
					);

					if (time === 0) this.deviceFacade.playAudio('time-over');

					if (over) {
						if (score === MemoryPhaseConstants.maxGridScore)
							this.deviceFacade.playAudio('large-cheer');
						else if (score >= MemoryPhaseConstants.halfGridScore)
							this.deviceFacade.playAudio('cheer');
					}
				}),
			)
			.subscribe(() => EMPTY);

		this.memoryPhaseSockets.gridMissed$
			.pipe(delay(FLIP_ANIMATION_DURATION))
			.subscribe(({ memoryGrid }: MemoryPhaseGridMissedEvent) => {
				for (const cell of memoryGrid.cells) {
					this.store.dispatch(
						MemoryCellActions.updateSilentSuccess({
							gridId: memoryGrid._id,
							cell,
						}),
					);
				}
			});

		this.memoryPhaseSockets.gridFixed$.subscribe(
			({ memoryGrid }: MemoryPhaseGridFixedEvent) => {
				this.store.dispatch(
					MemoryGridActions.fixSuccess({
						grid: memoryGrid,
					}),
				);
			},
		);
	}

	public fix(gridId: string, wordIndexHitted: MemoryPhaseIndex[]): void {
		this.store.dispatch(
			MemoryGridActions.fix({
				gridId,
				wordsNumberHitted: wordIndexHitted,
			}),
		);
	}

	public togglePlaying(
		gridId: string,
		playing: boolean,
		status: MemoryGridStatus,
	): void {
		if (!playing || status === MemoryGridStatus.ReadyForPlaying) {
			this.store.dispatch(
				MemoryGridActions.togglePlaying({ gridId, playing }),
			);
			this.toggleTimer(gridId, playing);
		} else {
			this.store.dispatch(
				MemoryGridActions.flip({
					gridId,
					status: MemoryGridStatus.Preview,
				}),
			);
			this.togglePreviewTimeout(gridId);
		}
	}

	private togglePreviewTimeout(gridId: string): void {
		const previewTimeout = 3;
		let previewTime = 0;
		this._timeoutSubscription = this._interval$
			.pipe(
				tap(() => {
					if (previewTime === previewTimeout) {
						this.store.dispatch(
							MemoryGridActions.flip({
								gridId,
								status: MemoryGridStatus.ReadyForPlaying,
							}),
						);
						this._timeoutSubscription.unsubscribe();
					}
					previewTime++;
				}),
			)
			.subscribe(() => EMPTY);
	}

	private toggleTimer(gridId: string, playing: boolean): void {
		if (playing && this._intervalSubscriptionIsOpen) return;

		if (!playing && this._intervalSubscriptionIsOpen)
			this._intervalSubscription.unsubscribe();

		if (!playing) return;

		this._intervalSubscription = this._interval$
			.pipe(
				withLatestFrom(
					this.store.select(MemoryGridSelectors.time(gridId)),
					this.store.select(GamesSelectors.id),
				),
				tap(([, time, gameId]: [number, number, string]) => {
					const newTime = time - 1;
					this.memoryPhaseSockets.emitTimeChanged({
						gameId,
						memoryGridId: gridId,
						time: newTime,
					});
					this.store.dispatch(
						MemoryGridActions.updateSuccess({
							grid: {
								_id: gridId,
								time: newTime,
							},
						}),
					);

					if (newTime === 0) {
						this.store.dispatch(
							MemoryGridActions.togglePlaying({
								gridId,
								playing: false,
							}),
						);
						this._intervalSubscription.unsubscribe();
					}
				}),
			)
			.subscribe(() => EMPTY);
	}
}
