import { PhaseKind } from '@api/models/enums';
import { MemoryCell } from '@api/models/memory-cell.entity';
import { MemoryGrid } from '@api/models/memory-grid.entity';
import { MemoryPhase } from '@api/models/memory-phase.entity';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Phase } from '@bussiness/custom-models/game.custom-models';
import { createSelector } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import { selectGames } from '../../games.selectors';

export class MemoryCellSelectors {
	public static current = (gridId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const grid = getGridFrom(gamesState, gridId);
			if (!grid) return null;

			return grid.cells
				.slice()
				.sort((a, b) => (a.answerIndex > b.answerIndex ? 1 : -1))
				.find(({ hitted }: MemoryCell) => !hitted);
		});
}

const getGridFrom = (gamesState: GamesState, gridId: string): MemoryGrid => {
	if (!gamesState.current) return null;

	const memoryPhase = gamesState.current.phases.find(
		({ kind }: Phase) => kind === PhaseKind.Memory,
	) as MemoryPhase;

	if (!memoryPhase) return null;

	return memoryPhase.grids.find(({ _id }: MemoryGrid) => _id === gridId);
};
